import React from 'react';
import BellImg from './bell.png';

const Bell = () => {
  return (
    <img 
          className="bell" 
          src={BellImg}
          alt=""
          style={{
            width: `${Math.floor(Math.random() * 60) + 15}px`,
            top: `${Math.floor(Math.random() * 75)}%`,
            left: `${Math.floor(Math.random() * 100)}%`,
            animationDelay: `.${Math.floor(Math.random() * 4)}s`
          }}
        />
  )
}

const Bells = ({showing})=> {
  return (
    <div className="bells-container">
      {showing &&
        <>
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
          <Bell />
        </>
      }
      
    </div>
  )
}

export { Bells }
