import React, {useState, useEffect} from 'react';
import { PushButton } from './PushButton';
import { Background } from './Background';
import { Bells } from './Bells';
import { Truck } from './Truck';
import './App.css';

function App() {
  const [imgIdx, setImgIdx] = useState(1);
  const [showBells, setShowBells] = useState(false);
  const MAX_IMG_IDX = 7;
  const setShowBellTO = ()=> {
    return setTimeout( ()=> {
      setShowBells(false);
    }, 1200);
  }
  let showBellTO = null;

  const handlePress = ()=> {
    setImgIdx(imgIdx === MAX_IMG_IDX ? 1 : imgIdx+1);
    clearTimeout(showBellTO);
    setShowBells(false);
    setShowBells(true);
    showBellTO = setShowBellTO();
  }

  useEffect(() => {
    window.preloadImages = [1,2,3,4,5,6,7].map(i => {
      const img = new Image();
      img.src = `${process.env.PUBLIC_URL}/${i}.jpg`;
      return img;
    });
  }, []);

  return (
    <div className="App">
      <Background imgIdx={imgIdx} />
      <Bells showing={showBells} />
      <Truck />
      <div className="content">
        <PushButton callback={handlePress} />
        <footer>
          <h1><strong>Riverside,</strong> mother fucker</h1>
          <p>Made with love for the 951 by <a href="https://twitter.com/rezassar">Reza</a></p>
        </footer>
      </div>
    </div>
  );
}

export default App;
