import React, { useState, useEffect } from 'react';
import BigTruck from './bigTruck.png';

const Truck = ()=> {
  const [direction, setDirection] = useState(-1);
  const [initialShow, setInitialShow] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setInitialShow(true);
      setDirection(direction => direction *-1);
    }, 6000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="truck-container">
      <img 
        src={BigTruck} 
        alt="" 
        className="truck" 
        style={{
          transform: `scaleX(${direction})`,
          left: direction > 0 ? '-40%' : '120%',
          display: !initialShow ? 'none' : 'block'
        }} />
    </div>
  )
}

export { Truck };
