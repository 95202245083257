import React from 'react'

const PushButton = ({callback}) => {
  const sound = new Audio(`${process.env.PUBLIC_URL}/rmf.mp3`)
  const handleClick = ()=> {
    sound.play();
    callback();
  };

  return (
    <div className="button-wrapper">
      <button className="push--flat" onClick={handleClick}></button>   
    </div>
  )
}

export { PushButton }